@import '../../styles/variables.breakpoints.module.scss';
@import '../../styles/mixins.scss';

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.email-modal {
  width: 356px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12px;
  padding: var(--spacing-2) 0;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-3);

  .title {
  color: var(--white);
  margin: var(--spacing-3) 0 5px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: var(--spacing-3);
  text-align: center;
}

  p {
    font-size: var(--font-size-body-md);
    color: var(--gray-100);
    text-align: center;
    margin: 0;
  }
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.email-input {
  width: 288px;
  border: 1px solid var(--gray-600);
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.8px;
  font-weight: var(--font-weight-light);
  color: var(--white);
  font-size: var(--font-size-body-md);
  outline: none;
  transition: border-color 0.3s;
  padding: var(--spacing-1) 20px;

  &:focus {
    border-color: var(--white);
    border-width: 2px;
  }

  &.input-error {
    border-color: #f52500;
    border-width: 2px;

    &:focus {
      border-color: #f52500;
    }
  }

  &:focus + .floating-label,
  &:not(:placeholder-shown) + .floating-label {
    top: -1px;
    left: var(--spacing-2);
    font-size: var(--font-size-body-xsm);
    padding: 0 4px;
    background-color: var(--gray-900);
    color: var(--white);
    z-index: 2;
    margin-left: 4px;

    &.error {
      color: #f52500;
    }
  }

  &:focus::placeholder {
    opacity: 0;
  }
}

.floating-label {
  position: absolute;
  top: 50%;
  left: var(--spacing-2);
  transform: translateY(-50%);
  color: var(--gray-300);
  transition: 0.2s ease all;
  pointer-events: none;
  background-color: transparent;
  padding: 0 4px;
  z-index: 2;

  &.error {
    color: #f52500;
  }
}

.cta-container {
  display: flex;
  justify-content: center;
  padding-top: var(--spacing-5);
}

.input-error {
  border-color: #f52500;
  border-width: 2px;
}

.error-message {
  color: #f52500;
  font-size: var(--font-size-body-sm);
  margin-top: 5px;
  text-align: left;
  margin-left: 20px;
}

.continue-button {
  padding: var(--spacing-1) 35px;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  background: linear-gradient(251.02deg, #c6c6c6 15.2%, #ffffff 70.17%);
  font-size: var(--font-size-body-sm);
  box-sizing: border-box;
  font-weight: 300;
  letter-spacing: 0.7px;
  border: 1px solid #e2e2e2;
  border-radius: 40px;

  &:hover {
    background: linear-gradient(248.24deg, #FFFFFF 17.66%, #C6C6C6 71.37%);
  }
}