@import '../../styles/variables.breakpoints.module.scss';
@import '../../styles/mixins.scss';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--black-rgba), 0.8);

  .icon-close-container {
    @include btn-reset-styles();
    position: absolute;
    top: var(--spacing-2);
    right: var(--spacing-2);
    padding: var(--spacing-0);

    svg {
      width: 20px;
      height: 20px;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    position: relative;
    padding: var(--spacing-5) var(--spacing-2) var(--spacing-3);
    width: 420px;
    background-color: var(--gray-900);
    backdrop-filter: blur(4px);
    border-radius: var(--spacing-2);
    text-align: center;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--spacing-3);

    .cta-panel {
      width: 100%;
      display: flex;
      gap: var(--spacing-2);

      button {
        height: 44px;
        width: 100%;
        border-radius: 40px;
        border: 0;
        border-color: transparent;
        font-size: 16px;
        color: var(--white);
        background-color: var(--gray-900);
        border: 1px solid #e2e3e4;
        transition: background 0.4s, border 0.2s;

        &:hover {
          color: var(--black);
          border: 1px solid transparent;
          background: linear-gradient(250deg, var(--white) 19%, var(--gray-200) 75%);
          cursor: pointer;
        }
      }

      button:not([data-type='secondary']):last-child {
        background: linear-gradient(250deg, var(--gray-200) 19%, var(--white) 75%);
        color: var(--black);

        &:hover {
          background: var(--black);
          color: var(--white);
          cursor: pointer;
        }
      }
    }

    &.modal-content--4, .modal-content--5, &.modal-content--6 {
      position: unset;
      width: 404px;
      padding: var(--spacing-3);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-3);

      & > div {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 278px;
        align-items: center;
      }

      .icon-close-container {
        position: unset;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .cta-panel {
        display: flex;
        justify-content: center;

        button {
          height: unset;
          width: 134px;
          padding: var(--spacing-1) var(--spacing-2);
        }
      }
    }

    &.modal-content--6 {
      padding: var(--spacing-3) var(--spacing-3) var(--spacing-5);
      .cta-panel {
        button {
          margin-top: var(--spacing-2);
        }
      }
    }
  }
}

.copy-link-button {
  @include btn-reset-styles();
  background: transparent;
  cursor: pointer;
  padding: var(--spacing-2) var(--spacing-0);

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-1);
    color: var(--turquoise-300);
    font-size: var(--font-size-body-md);
    font-weight: var(--font-weight-light);
    line-height: 150%;
    position: relative;

    span:first-of-type {
      visibility: hidden;
      width: max-content;
      background: var(--white);
      position: absolute;
      z-index: 1;
      top: 175%;
      color: var(--black);
      padding: var(--spacing-1) var(--spacing-2);
      font-size: var(--font-size-body-sm);
      font-weight: var(--font-weight-light);
      line-height: var(--line-height-body);
      border-radius: 8px;
      letter-spacing: 0;
      text-align: center;
      opacity: 1;
    }

    span:first-of-type::after {
      //The point of the tooltip
      content: '';
      position: absolute;
      left: calc(50% - 8.5px);
      bottom: 100%;
      border-width: 8.5px;
      border-style: solid;
      border-color: transparent transparent var(--white) transparent;
    }

    span:first-of-type.show {
      opacity: 1;
      visibility: visible;
      animation: fadeOut 400ms ease-in-out 2600ms;
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
}