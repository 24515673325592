@import '../../styles/variables.breakpoints.module';
@import '../../styles/mixins.scss';

#root {
  --white: hsla(0, 0%, 100%, 1);
}

.login-form-container {
  height: 100vh;
  position: fixed;
  top: 0;

  .error-message {
    margin-bottom: var(--spacing-2);
    background-color: transparent;
    padding-bottom: var(--spacing-1);
    border-radius: var(--spacing-1);
    color: #f52500;
    display: flex;
  }

  .login-form-top-content {
    width: 624px !important;

    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    border-radius: var(--spacing-2);
    border: 1px solid var(--gray-500);
    padding: var(--spacing-5);
    background-color: rgba(var(--black-rgba), 0.7);
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2), 0px 4px 24px rgba(0, 0, 0, 0.2);
    transition: all 2s ease;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint-up(xl) {
      transform: translate(-95%, -50%);
    }

    @include breakpoint-up(xxl) {
      transform: translate(-125%, -50%);
    }

    strong[data-type='forgot-password-email'] {
      font-weight: var(--font-weight-extra-bold);
    }

    .login-form {
      margin-top: var(--spacing-10);
      display: flex;
      flex-direction: column;
      width: 100%;

      .form-group {
        position: relative;
        margin-bottom: var(--spacing-2);
        display: flex;
        font-size: 18px;
        color: var(--gray-500);

        /** Custom material-like input text fields */
        input[type='password'],
        input[type='email'],
        input[type='text'] {
          flex: 1;
          color: var(--white);
          border-radius: var(--spacing-1);
          outline: none;
          border: 1px solid var(--gray-500);
          padding: var(--spacing-1) var(--spacing-2);
          caret-color: var(--white);
          font-size: var(--font-size-specialty-form-inputs-desktop);
          background-color: rgba(var(--black-rgba), 0.8);

          &:focus {
            border-color: var(--white);

            &::placeholder {
              color: transparent;
            }
          }

          &:focus + label,
          &:not(:placeholder-shown) + label {
            top: 0;
            left: 14px;
            font-size: 12px;
            background-color: black;
            padding-left: 4px;
            padding-right: 4px;
            color: var(--white);
          }

          + label {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 17px;
            transition: all 0.25s ease-out;
            pointer-events: none;
          }
        }

        .toggle-password {
          position: absolute;
          top: 7px;
          right: 17px;
          cursor: pointer;
        }

        &.form-group--error {
          margin-bottom: var(--spacing-1);
          input[type='password'],
          input[type='email'],
          input[type='text'] {
            &[id='confirmPassword'] {
              color: #f52500;
            }
            border-color: #f52500;

            + label {
              color: #f52500;
            }
            &::placeholder {
              color: #f52500;
            }
          }
        }

        /** Custom checkbox */
        .checkbox {
          input[type='checkbox'] {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;

            //custom checkmark
            &:checked + label::after {
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              left: var(--spacing-1);
              transform: translateY(-50%) rotate(45deg);
              width: var(--spacing-1);
              height: var(--spacing-2);
              border: solid #000;
              border-width: 0 2px 2px 0;
            }

            &:checked + label::before {
              background-color: var(--white);
              border-color: var(--white);
            }

            &:disabled + label:hover::after,
            &:disabled + label:hover::before,
            &:disabled + label {
              cursor: default;
              color: var(--gray-500);
            }
          }

          label {
            position: relative;
            color: var(--white);
            cursor: pointer;

            //custom checkbox
            &::before {
              content: '';
              cursor: pointer;
              -webkit-appearance: none;
              appearance: none;
              background-color: rgba(var(--black-rgba), 0.8);
              border: 1px solid var(--gray-500);
              border-radius: var(--spacing-1);
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
              padding: 12px;
              display: inline-block;
              position: relative;
              vertical-align: middle;
              cursor: pointer;
              margin-right: var(--spacing-1);
            }
          }
        }

        .remember-me-label {
          font-size: var(--font-size-specialty-label-desktop);
        }
        a.remember-me-label {
          font-weight: var(--font-weight-extra-bold);
        }
      }

      button {
        border: none;
        font-size: var(--font-size-specialty-form-inputs-desktop);
        padding: var(--spacing-1) var(--spacing-3);
        border-radius: var(--spacing-3);
        background: linear-gradient(251.02deg, #20ffff 19.17%, #05c3dd 75.2%);

        &:hover {
          cursor: pointer;
        }

        &.btn-white {
          @include btn(lg, light, solid);
        }
      }
    }

    .back-to-signin {
      color: var(--turquoise-300);
      margin: var(--spacing-2) auto 10px auto;
      font-size: var(--font-size-body-md);
      &:hover {
        color: var(--link-color-brand-primary-hover);
      }
    }
  }

  .login-form-footer {
    z-index: 2;
    width: 100vw;
    border-top: 1px solid var(--gray-700);
    background-color: rgba(var(--black-rgba), 0.45);
    position: fixed;
    left: 0;
    bottom: 0;
    padding: var(--spacing-2) var(--spacing-3);
    backdrop-filter: blur(24px);

    p {
      text-align: right;
      color: var(--gray-500);
      font-size: var(--font-size-body-xsm);
      margin: 0;
    }
  }

  .login-full-screen__overlay {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(var(--black-rgba), 0.4);
    z-index: 1;
  }

  video {
    height: 100%;
    width: 100vw;
    object-fit: cover;
    object-position: center center;
    transform: scale(2);
    transition: all 2s ease-out;

    @include breakpoint-up(xl) {
      transform: scale(1.75) translateX(150px);
    }

    @include breakpoint-up(xxl) {
      transform: scale(1.75) translateX(225px);
    }
  }
}
