@import '../../styles/variables.breakpoints.module.scss';
@import '../../styles/mixins.scss';

.level-1 {
  @include heading(1);
}

.level-2 {
  @include heading(2);
}

.level-3 {
  @include heading(3);

  a {
    @include link(white);
  }
}

.level-4 {
  @include heading(4);

  a {
    @include link(white);
  }
}

.level-5 {
  @include heading(5);
}

.level-6 {
  @include heading(6);
}

.level-display {
  @include heading(display);
}

.eyebrow {
  @include heading(label);
}

.to-uppercase {
  text-transform: uppercase;
}

.heading {
  &.justify-center {
    text-align: center;
  }

  &.justify-left {
    text-align: left;
  }

  @each $margin-level, $margin-value in $margins_headings {
    &.margin-top-#{$margin-level} {
      margin-top: $margin-value;
    }

    &.margin-bottom-#{$margin-level} {
      margin-bottom: $margin-value;
    }
  }
}
