@import '../../styles/variables.breakpoints.module.scss';
@import '../../styles/mixins.scss';

.terms-content {
  color: var(--white);
  padding: var(--spacing-2) 0;
  width: 485px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    margin: var(--spacing-3) 0 var(--spacing-1) 0;
    font-size: var(--font-size-body-lg);
    font-weight: var(--font-weight-extra-bold);
    line-height: var(--spacing-3);
    text-align: center;
  }

  .normal-text {
    margin-bottom: 30px;
    font-weight: var(--font-weight-light);
    line-height: var(--spacing-3);
    text-align: center;
    color: var(--gray-100);
    width: 447px;
    padding: 0 var(--spacing-1);
    width: 100%;
  }
}

.agreement-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-1);

  label {
    color: var(--gray-100);
    font-size: var(--font-size-body-md);
    font-weight: var(--font-weight-light);
    line-height: 19.2px;
    letter-spacing: 0.05em;
    margin-left: 10px;
  }
}

.agreement-container.error {
  .custom-checkbox {
    input[type='checkbox'] {
      border-color: #f52500;
    }
  }
}

.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;

  input[type='checkbox'] {
    appearance: none;
    width: var(--spacing-3);
    height: var(--spacing-3);
    border: 1px solid #757575;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    &:checked {
      background-color: var(--white);
      border-color: var(--white);
    }

    &:checked::after {
      content: '';
      position: absolute;
      left: 7px;
      top: 1px;
      width: var(--spacing-1);
      height: 14px;
      border: solid var(--black);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.confirm-button {
  padding: var(--spacing-1) 35px;
  border-radius: 8px;
  background: linear-gradient(251.02deg, #c6c6c6 15.2%, #ffffff 70.17%);
  cursor: pointer;
  font-size: var(--font-size-body-sm);
  box-sizing: border-box;
  font-weight: var(--font-weight-light);
  letter-spacing: 0.7px;
  border: 1px solid var(--gray-100);
  border-radius: 40px;
  padding: var(--spacing-1) var(--spacing-2);
  margin-top: var(--spacing-5);
  width: 134px;
  
  &:hover {
    background: linear-gradient(248.24deg, #FFFFFF 17.66%, #C6C6C6 71.37%);
  }
}

.error-message {
  color: #f52500;
  text-align: center;
  margin-top: -6px;
  font-size: var(--font-size-body-xsm);
}