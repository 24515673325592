@import '../../styles/mixins.scss';

.exit-preview-button {
  @include btn-reset-styles();
  border: none;
  background: transparent;
  padding: var(--spacing-0);
  flex: 0 0 auto;

  &:hover {
    cursor: pointer;
  }
}
