@import '../../styles/variables.breakpoints.module.scss';
@import '../../styles/mixins.scss';

.modal-content-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.modal-header {
  width: 100%;
  padding: var(--spacing-0) var(--spacing-1) var(--spacing-2);

  h4 {
    display: flex;
    max-width: 575px;
    white-space: nowrap !important;

    span[data-testid='experience-id'] {
      text-overflow: ellipsis;
      overflow: hidden;
      direction: rtl;
      white-space: nowrap;
    }

    span[data-testid='DRPGG'] {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.modal-body-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);

  .email-input {
    width: 100%;
    height: 100%;
    min-height: var(--spacing-7);
    padding: 14px var(--spacing-2);
    background: rgba(0, 0, 0, 0.1);
    outline: 1px solid var(--gray-500);
    outline-offset: -1px;
    color: var(--white);
    font-size: var(--font-size-body-md);
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-1);

    &:hover,
    &:focus-within {
      outline: 2px solid var(--white);
      outline-offset: -2px;
    }

    &:focus-within {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.45) 100%);
    }

    .text-input {
      line-height: var(--line-height-body);
      flex: 1 0 auto;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      max-width: 100%;
      font-weight: var(--font-weight-light);
    }

    [data-placeholder]:empty:before {
      content: attr(data-placeholder);
      position: absolute;
      background-color: transparent;
      color: var(--gray-300);
      font-weight: var(--font-weight-light);
    }
    [data-placeholder]:empty:focus::before {
      content: '';
      outline: none;
    }

    [contenteditable='true']:focus,
    [contenteditable='true']:active {
      outline: 0px solid transparent;
    }

    .valid-email,
    .invalid-email {
      background: var(--gray-700);
      padding: 4px var(--spacing-1);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-2);
      max-width: 100%;
      
      cursor: pointer;
      height: 100%;

      .email {
        color: var(--white);
        line-height: 120%;
        overflow-wrap: break-word;
        overflow: hidden;
        letter-spacing: 0.8px;
      }

      .delete-button {
        @include btn-reset-styles();
        width: 12px;
        height: 12px;
        padding: 0;
        cursor: pointer;
      }
    }

    .valid-email {
      &:hover,
      &.selected-email {
        background: var(--gray-100);

        .email {
          color: var(--gray-800);
        }
      }
    }

    .invalid-email {
      background: transparent;
      outline: 1px solid red;
      outline-offset: -1px;

      .email {
        color: red;
      }
    }
  }

  .notify-details-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .notify-left {
      display: flex;
      align-items: center;
    }

    .notify-right {
      margin-left: auto;
    }

    .notify-label {
      display: flex;
      align-items: center;
      color: var(--gray-300);
      font-size: var(--font-size-body-sm);
      font-weight: var(--font-weight-light);
      line-height: var(--line-height-body);
      margin-left: var(--spacing-1);
    }

    .notify-checkbox {
      position: relative;
      display: flex;
      align-items: center;

      input[type='checkbox'] {
        appearance: none;
        width: 22px;
        height: 22px;
        border: 1px solid var(--gray-500);
        border-radius: 8px;
        background-color: transparent;
        cursor: pointer;
        position: relative;
        margin: 0;
        transition: background-color 0.3s ease, border-color 0.3s ease;

        &:checked {
          background-color: var(--white);
          border-color: var(--white);
        }

        &:checked::after {
          content: '';
          position: absolute;
          left: 5px;
          width: 8px;
          height: 14px;
          border: solid var(--black);
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
        }
      }

      input[type='checkbox']:checked+.notify-label {
        color: var(---white);
      }
    }

    .num-share-status {
      font-size: var(--font-size-body-sm);
      font-weight: var(--font-weight-light);
      line-height: var(--line-height-body);
      color: var(--gray-400);
    }
  }
  
  .message-div {
    width: 640px;
    height: 100px;
    padding: var(--spacing-1) var(--spacing-2);
    border: 1px solid var(--gray-500);
    border-radius: 8px;
    font-size: var(--font-size-body-sm);
    color: var(--white);
    background-color: transparent;
    outline: none;
    resize: none;

    &:focus {
      border-color: var(--white);
    }

    &.error {
      border-color: #F52500;
    }
  }

  .message-div:focus::placeholder {
    color: transparent;
  }

  .character-limit-warning {
    color: #F52500;
    font-size: var(--font-size-body-xsm);
    margin: 0px 20px 0px;
  }

  .who-can-access-container {
    display: flex;
    flex-direction: column;
    max-height: 460px;
    overflow: hidden;
    margin-bottom: -8px;
  }

  .who-can-access-title {
    font-size: var(--font-size-h6-desktop);
    font-weight: var(--font-weight-extra-bold);
    text-align: left;
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    color: var(--gray-400);
  }

  .recipient-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    scrollbar-width: none;
  }

  .recipient-item {
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    padding: var(--spacing-1);
    border-top: 1px solid var(--gray-700);
    margin-left: -8px;
  }

  .recipient-item:last-child {
    border-bottom: none;
  }

  .recipient-email {
    font-size: var(--font-size-body-md);
    color: var(--white);
    flex: 1;
    line-height: 150%;
  }
}

.modal-control-panel {
  width: 100%;
  padding: 12px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;

  .access-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    gap: var(--spacing-1);
  }

  .item {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--color-neutral-gray-500, #757575);
    padding: 5px 10px;
    border-radius: 6px;
    justify-content: space-between;
    width: 208px;

    .name {
      margin-right: 10px;
    }

    .number {
      padding: 3px 6px;
      border-radius: 3px;
      color: #ababab;
    }
  }

  .divider {
    width: 100%;
    border-top: 1px solid var(--gray-700);
    margin: 16px 0;
  }
}

.panel-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .copy-link-button {
    padding: var(--spacing-1) var(--spacing-3);
    background-color: transparent;
    cursor: pointer;
    color: var(--white);
    font-size: 18px;
    border: 1px solid var(--gray-100);
    border-radius: 40px;
    font-weight: var(--font-weight-light);
    letter-spacing: 0.9px;

    &:hover {
      border: 1px solid var(--black);
      background-color: var(--black);
    }

    .display-text {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-1);
      align-items: center;
      justify-content: center;
      position: relative;

      .tooltip-text {
        visibility: hidden;
        width: max-content;
        background: var(--white);
        position: absolute;
        z-index: 1;
        color: var(--black);
        padding: var(--spacing-1) var(--spacing-2);
        font-size: var(--font-size-body-sm);
        font-weight: var(--font-weight-light);
        line-height: var(--line-height-body);
        border-radius: 8px;
        letter-spacing: 0;
        text-align: center;
        opacity: 1;
        margin-top: 100%;
      }

      .tooltip-text::after {
        //The point of the tooltip
        content: '';
        position: absolute;
        left: calc(50% - 8.5px);
        bottom: 100%;
        border-width: 8.5px;
        border-style: solid;
        border-color: transparent transparent var(--white) transparent;
      }

      .tooltip-text.show {
        opacity: 1;
        visibility: visible;
        animation: fadeOut 400ms ease-in-out 2600ms;
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    }
  }

  .share-button {
    @include btn-reset-styles();  
    cursor: pointer;
    font-size: 18px;
    font-weight: var(--font-weight-light);
    display: inline-flex;
    flex-direction: row;
    letter-spacing: 0.9px;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-1) var(--spacing-3);
    gap: var(--spacing-1);
    border-radius: 40px;
    background: linear-gradient(251deg, #20FFFF 19.17%, #05C3DD 75.2%);
  }

  .share-button:disabled {
    background: var(--gray-600);
    color: var(--gray-300);
    cursor: not-allowed;
  }

  .share-button:hover:not([disabled]) {
    background: linear-gradient(248deg, #05C3DD 27.85%, #20FFFF 81.23%);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.50);
  }
}

.invalid-email[tooltip] {
  position: relative;
  padding: var(--spacing-1) var(--spacing-2);
}

.invalid-email[tooltip]::before { //Tooltip tail
  content: "";
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8.5px;
  border-style: solid;
  border-color:  transparent transparent var(--white) transparent;
}

.invalid-email[tooltip]::after { //Tooltip textbox
  content: attr(tooltip);
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: calc(100% + 15px);
  transform: translateX(-50%);
  background: var(--white);
  text-align: center;
  color: var(--black);
  padding: var(--spacing-1) var(--spacing-2);
  font-size: var(--font-size-body-sm);
  border-radius: 8px;
  pointer-events: none;
  white-space: nowrap;
}

.invalid-email[tooltip]:hover {
  &::before, &::after {
    visibility: visible;
    z-index: 1;
  }
}

.heading {
  white-space: pre-line;
}