@import '../../styles/variables.breakpoints.module.scss';
@import '../../styles/mixins.scss';

.access-denied-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-1) var(--spacing-3);
  text-align: center;
  width: 356px;
  gap: 12px;

  .heading {
    margin: 0;
    font-size: var(--font-size-body-lg);
    font-weight: var(--font-weight-extra-bold);
    line-height: 1.5;
    color: var(--white);
  }
}

.paragraph-container {
  color: var(--gray-100);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-light);
  line-height: 1.5;
}